import { campaignAxios } from './axios'

export const createCampaign = async({campaignName, numberOfTickets}) => {
    try {
        await campaignAxios.post('/',{ campaignName, numberOfTickets, createBy: 'yair' })
    } catch(error) {
        console.error(error);
    }
}
export const getCampaignList = async({campaignName, numberOfTickets}) => {
    try {
        const campaignList =  await campaignAxios.get('/');
        return campaignList
    } catch(error) {
        console.error(error);
    }
}

export const fetchAllCampaignList = async (access_token) => {
    try {
        // const access_token = await getAccessToken()
        // console.log({access_token});
        const campaignList =  await campaignAxios.get('/');
        return campaignList.data
    } catch (error) {
        console.error(error);
    }
}

export const getAccessToken = async () => {
    var options = { method: 'POST',
  url: 'https://dev-3ibaojjd.us.auth0.com/oauth/token',
  headers: { 'content-type': 'application/json' },
  body: '{"client_id":"Rt56BvHDeZvmJkGeltMb9YFiVmnKWbiW","client_secret":"kuEHTQpPwFwLiDO0IHvxMGMCLxAp8Uf4oUTk-V40B3dpehmyaBB_kk_ITOsLMZKf","audience":"https://localhost:3000","grant_type":"client_credentials"}' };
    try {
        const {access_token} = await campaignAxios.request(options)
        console.log(access_token);

        return access_token

    }  catch (error) {
        console.error(error)
    }
}

export const filterTable = async (query) => {
    try {
        console.log(query);
        const campaignList =  await campaignAxios.get('/auto-complate', { params: query });
        return campaignList.data
    } catch(error) {

    }
    
}

export const updateFulfilled = async (updateData, userName) => {
    try {
        const updatedCampaignList =  await campaignAxios.put('/', { updateData, userName });
        console.log({updatedCampaignList});
    } catch(error) {

    }
}

export const deleteRows = async (rowsToDelete, userName) => {
    try {
        const rowsIds = rowsToDelete.map(row => row.uuid);
        const deletedRows = await campaignAxios.delete(`/${rowsIds.join(',')}`, {data: {userName} });
        console.log({deletedRows});
    } catch (error) {

    }
}