import { useContext, useState, useRef } from 'react'
import { appContext } from '../../App'
import { DataGrid } from '@mui/x-data-grid';
import { columns, SUBMIT_MASSAGE, MIXED_MASSEGE, RETURN_MASSEGE } from './const'
import { filterTable, updateFulfilled, fetchAllCampaignList, deleteRows } from '../../services/api'
import { APP_STATE_ACTIONS } from '../../App';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import BasicModal from '../Modal';
import Toaaster from '../Toaster'
import './index.scss'

const CampaignTable = ({ rows, user }) => {
    const context = useContext(appContext)
    const containerRef = useRef(null);
    const [selectedIds, setSelectedIds] = useState([])
    const [modelState, setModelState] = useState(false)
    const [action, setAction] = useState()
    const [isLoading, setisLoading] = useState(false)
    const onFilterChange = async (filterData) => {
        const query = filterData.items.reduce((acc, item) => {
            acc[item.columnField] = item.value;
            return acc;
        }, {})
        const campaignList = await filterTable(query)
        context.appDispatch({
            action: APP_STATE_ACTIONS.UPDATE_CAMPAIGN_LIST,
            data: {
                campaignList
            }
          })
    }
    const onCheckboxSelect = (selectedIds) => {
        setSelectedIds(selectedIds)
    } 
    const submmitDataToUpdate = async (action) => {
        setModelState(true)
        console.log('submit',context.appState.campaignList);
        console.log('submit',selectedIds);
        const rowsToUpdate = context.appState.campaignList.filter(campaign => selectedIds.includes(campaign.uuid))
        console.log({rowsToUpdate});
        setisLoading(true)
        if (action === 'toggle') {
            await updateFulfilled(rowsToUpdate, user.name)
        } else {
            await deleteRows(rowsToUpdate, user.name)
        }
        const campaignList = await fetchAllCampaignList()
        context.appDispatch({
            action: APP_STATE_ACTIONS.UPDATE_CAMPAIGN_LIST,
            data: {
                campaignList
            }
          })
        setisLoading(false)
        setModelState(false)
    }
    const handleButtonClicked = (action) => {
        setAction(action)
        setModelState(true)
    }
    const modalMainText = selectedIds.map((id) => context.appState.campaignList.find(doc => doc.uuid === id )).reduce((acc, doc) => {
        if (!doc.fulfilled && !acc) {
            acc = SUBMIT_MASSAGE
            return acc
        }
        else if(acc) {
            acc = MIXED_MASSEGE
            return acc
        }
        else {
            acc = RETURN_MASSEGE
            return acc
        }
    }, '')
    return <div className='table-container'
        ref={containerRef}
        >
        <Toaaster  isChecked={!isLoading} containerRef={containerRef}/>    
        <BasicModal 
            openFrom={modelState} 
            handleClose={() => setModelState(false)}
            mainText ={modalMainText.title} 
            secondaryText ={modalMainText.secondaryTitle} 
            cb={submmitDataToUpdate}
            action={action}
            isLoading={isLoading}
        />
        <DataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            checkboxSelection
            disableSelectionOnClick
            onFilterModelChange={onFilterChange}
            onSelectionModelChange={onCheckboxSelect}
            getRowClassName={(params) => `super-app-theme--${params.row.fulfilled}`}
        />
        <div className="butoons-container" style={{marginTop: '20px', width: '300px', display: 'flex', justifyContent: 'space-around', alignSelf: 'center'}}>
            <Button variant="outlined" endIcon={<SendIcon />} disabled={!selectedIds.length} onClick={() => handleButtonClicked('toggle')}>submmit</Button>
            <Button variant="outlined" startIcon={<DeleteIcon />} disabled={!selectedIds.length} onClick={() => submmitDataToUpdate('delete')}>Delete</Button>
        </div>
    </div>
            
}

export default CampaignTable;