import { useState, useContext,useEffect } from "react"
import { appContext, APP_STATE_ACTIONS } from '../App'
import { createCampaign } from '../services/api'
import { useAuth0 } from "@auth0/auth0-react";


const CreateCampain = () => {
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [userMetadata, setUserMetadata] = useState(null);
    useEffect(() => {
        const getUserMetadata = async () => {
          const domain = "dev-3ibaojjd.us.auth0.com";
      
          try {
            const accessToken = await getAccessTokenSilently({
              audience: `https://ccb5-46-117-129-194.ngrok.io`,
              scope: "/",
            });
      
            const userDetailsByIdUrl = `https://ccb5-46-117-129-194.ngrok.io/`;
            console.log('hgg');
            const metadataResponse = await fetch(userDetailsByIdUrl, {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            });
      
            const { user_metadata } = await metadataResponse.json();
            console.log({user_metadata});
            setUserMetadata(user_metadata);
          } catch (e) {
            console.log(e.message);
          }
        };
      
        getUserMetadata();
      }, [getAccessTokenSilently, user?.sub]);
    const context = useContext(appContext)
    const submmitCampaign = async(ev) => {
        ev.preventDefault()
        console.log('logg')
        try {
            const campaignList = await createCampaign({campaignName, numberOfTickets})
            context.appDispatch({
                action: APP_STATE_ACTIONS.CREATE_CAMPAIGN,
                data: {
                    campaignName,
                    numberOfTickets,
                    campaignList
                }
            })
            setCampaignName('');
            setNumberOfTickets(0)
        } catch(error) {

        }
    } 

    const [campaignName, setCampaignName] = useState()
    const [numberOfTickets, setNumberOfTickets] = useState()
    return <div className="main-container">
        <h3>activate campain</h3>
        <span>{campaignName}</span>
        <span>{context.appState.campaignName}</span>
        <form  onSubmit={submmitCampaign}>
            <label htmlFor="campaign-name">שם הקמפיין</label>
            <input type="text" id="campaign-name" value={campaignName} onChange={(event) => setCampaignName(event.target.value)}/>

            <label htmlFor="number-of-ids">כמות כרטיסים</label>
            <input type="number" id="number-of-ids" value={numberOfTickets} onChange={(event) => setNumberOfTickets(event.target.value)} />
            <button type="submmit"  >SUBMMIT</button>
        </form>
    </div>
}

export default CreateCampain