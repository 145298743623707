import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import CancelIcon from '@mui/icons-material/Cancel';
import Button from '@mui/material/Button';
import './index.scss'


export default function BasicModal({openFrom, handleClose, mainText = '', secondaryText = '',action , cb = () => {}, isLoading}) {
  const [open, setOpen] = React.useState(false);
  

  React.useEffect(() => {
    setOpen(openFrom);
  },[openFrom])

  return (
    <div >
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className='modal-container'>
          <Typography className='main-text' id="modal-modal-title" variant="h6" component="h2">
            {mainText}
          </Typography>
          <Typography className='sec-text' id="modal-modal-description" sx={{ mt: 2 }}>
            {secondaryText}
          </Typography>
          <div className="action-container">
          <LoadingButton className='loading-button'
                loading={isLoading}
                loadingPosition="start"
                startIcon={<SendIcon />}
                variant="outlined"
                onClick={() => cb(action)}
            >
                המשך
          </LoadingButton>
          <Button 
            className='loading-button'
            variant="outlined" 
            startIcon={<CancelIcon />}
            onClick={handleClose}
            >
              ביטול
          </Button>


          </div>
        </Box>
      </Modal>
    </div>
  );
}
