import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Slide from '@mui/material/Slide';
import { useEffect, useState } from 'react';

const Toaster = ({isChecked, containerRef}) => {
    const [isFirstEntry, setIsFirstEntry] = useState(true)
    useEffect(() => {
        setIsFirstEntry(true)
    },[])
    useEffect(() => {
        console.log({isFirstEntry});
        if (isChecked && !isFirstEntry) {
            setChecked(true)
            setTimeout(() => {
                setChecked(false)
            },2500)
        }
        if (isFirstEntry) {
            setIsFirstEntry(false)
        }
    },[isChecked])
    const [checked, setChecked] = useState(false)
    const displayClass = checked ? 'show' : 'hide'
    return <div className={'toaster-container' + ' ' + displayClass}>
            <Slide direction="down" in={checked} container={containerRef.current}>
                <Alert severity="success">
                <AlertTitle>בוצע</AlertTitle>
                יאיי, הבקשה בוצעה   <strong>בהצלחה</strong>
                </Alert>
            </Slide>
        </div>
}

export default Toaster