import { useContext, useEffect } from 'react'
import { appContext } from '../App' 
import { useAuth0 } from "@auth0/auth0-react";
import CampaignTable from '../components/campaing-table';
import { fetchAllCampaignList } from '../services/api';
import { APP_STATE_ACTIONS, APP_PAGES } from '../App';
import Header from '../components/Header'
import { dateOptions } from '../components/campaing-table/const'
import './home.scss'

const Home = () => {
    const context = useContext(appContext)
    const { isAuthenticated, user } = useAuth0();


    useEffect(() => {
        const getAllCampaignList = async () => {
          try {
            const campaignList = await fetchAllCampaignList()
            console.log({campaignList});
            context.appDispatch({
              action: APP_STATE_ACTIONS.UPDATE_CAMPAIGN_LIST,
              data: {
                  campaignList
              }
            })
          } catch (error) {
            console.log(error);
          }
          
        }
        getAllCampaignList()
      },[])
    const { campaignList } = context.appState
    
    const rows = isAuthenticated && campaignList
        .filter(campaign => campaign.viewable)
        .map((row,idx) => ({ ...row, 
          id: row.uuid, 
          "#": idx+1,
          fulfilled: row.fulfilled ? 'כן' : 'לא',
          update_at: row?.update_at && new Date(row.update_at).toLocaleDateString('he',dateOptions)
        })) || []
    return <div className="main-container" style={{ display: 'flex', flexDirection: 'column' }}>
        <Header/>
        <div className="content" >
          <CampaignTable rows={rows} user={user}/>
        </div>
        
    </div>
}

export default Home