import Axios from 'axios'

Axios.defaults.withCredentials = true

export const campaignAxios = Axios.create({
    baseURL:'https://gw.icepeaks.link',
    withCredentials: true
})

campaignAxios.interceptors.response.use(res => res,error => {
    console.log('error : ',error);
    return Promise.reject(error)
})