import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { createContext, useEffect, useReducer } from 'react'
import './App.css';
import Home from './pages/Home';
import CreateCampain from './pages/CreateCampain';
import ActivateCode from './pages/ActivateCode';
import User from './pages/User'



export const STATUS = {
  OFF: 'off',
  PANDING: 'panding',
  COMPLATE: 'complate'
}

export const APP_STATE_ACTIONS = {
  CREATE_CAMPAIGN: 'create-campaign',
  UPDATE_CAMPAIGN_LIST: 'update-campaign-list',
  SET_CURRENT_PAGE: 'set-current-page',
  SET_IS_AUTHENTICATED: 'set-is-authenticated'
}

export const APP_PAGES = {
  HOME: {
    route: '/',
    name: 'Home'
  },
  CREATE_CAMPAIGN: {
    route: '/create-campain',
    name: 'Create campaign'
  },
  ACTIVATE_CODE: {
    route: '/activate-code',
    name: 'Activate code'
  }
}

const initialAppState = {
  status: STATUS.OFF,
  numberOfTickets: 0,
  campaignName: '',
  campaignList: [1,2,3,4,5],
  currentPage: APP_PAGES.HOME,
  isAuthenticated: false
}

const appReducer = (state, { action, data }) => {
  switch(action) {
    case APP_STATE_ACTIONS.CREATE_CAMPAIGN:
      console.log(data.campaignName);
      return { 
        ...state, 
        numberOfTickets: data.numberOfTickets, 
        campaignName: data.campaignName, 
        campaignList: data.campaignList,
        status: STATUS.PANDING 
      }
    case APP_STATE_ACTIONS.UPDATE_CAMPAIGN_LIST:
      console.log('in reducer');
      return {
        ...state,
        campaignList: data.campaignList
      }
    case APP_STATE_ACTIONS.SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: data.currentPage
      }
    case APP_STATE_ACTIONS.SET_IS_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: data.isAuthenticated
      }
    default:
      return state
  }
}

export const appContext = createContext()

function App() {
  useEffect(() => {
   
  },[])
  
  const [appState, appDispatch] = useReducer(appReducer, initialAppState)
  return (
    <div className="App">
      <appContext.Provider value={{appState, appDispatch}}>
        <BrowserRouter>
          <Routes>
              <Route path="/" element={<Home />}>
              </Route>
              <Route path="/user" element={<User/>}>
              </Route>
              <Route path="/create-campain" element={<CreateCampain />}>
            </Route>
            <Route path="/activate-code" element={<ActivateCode />}>
            </Route>
          </Routes>
        </BrowserRouter>
      </appContext.Provider>
  
    </div>
  );
}

export default App;
