export const columns = [
    { field: '#', headerName: '#', width: 30 },
    {
      field: 'campaign_name',
      headerName: 'שם קמפיין',
      width: 150,
      editable: false,
      headerAlign: 'center'
    },
    {
      field: 'uuid',
      headerName: 'קוד',
      width: 300,
      editable: false,
      headerAlign: 'center'
    },
    {
      field: 'created_at',
      headerName: 'תאריך יצירה',
      type: 'date',
      width: 150,
      editable: false,
      headerAlign: 'center'
    },
    {
      field: 'create_by',
      headerName: 'נוצר עי',
      width: 110,
      editable: false,
      headerAlign: 'center'
    },
    {
      field: 'update_at',
      headerName: 'תאריך עדכון',
      type: 'Date',
      width: 150,
      editable: false,
      headerAlign: 'center'
    },
    {
      field: 'update_by',
      headerName: 'עודכן עי',
      width: 150,
      editable: false,
      headerAlign: 'center'
    },
    {
      field: 'fulfilled',
      headerName: 'מומש',
      width: 110,
      editable: false,
      headerAlign: 'center'
    },
  ];

  export const dateOptions = {
    year: 'numeric', 
    month: 'numeric', 
    day: 'numeric', 
    hour: 'numeric', 
    minute:  'numeric' 
  }

  export const SUBMIT_MASSAGE = { title: 'מימוש כרטיס', secondaryTitle: 'אתה עומד לממש את הכרטיס, לחץ המשך  כדי להמשיך בתהליך' }

  export const MIXED_MASSEGE = { title: 'מימוש והפעלת כרטיסים', secondaryTitle: 'אתה עומד לממש ולהפעיל מחדש כרטיסים, לחץ המשך  כדי להמשיך בתהליך' }
  
  export const RETURN_MASSEGE = { title: 'הפעלת כרטיס', secondaryTitle: 'אתה עומד להפעיל מחדש כרטיס, לחץ המשך  כדי להמשיך בתהליך' }